import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from './components/Sidebar'; // Import the Sidebar component
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });
    }, []);

    const signInWithGoogle = async () => {
        console.log("Signing in with Google");
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
            await firebase.auth().signInWithPopup(provider);
        } catch (error) {
            console.error(error);
        }
    }

    const signOut = () => {
        firebase.auth().signOut();
    }

    console.log(signInWithGoogle);
    return (
        user ? (
            <div>
                <Router>
                    <Sidebar />
                </Router>
                <Button variant="contained" color="secondary" onClick={signOut} sx={{ mt: 2 }}>Sign out</Button>
            </div >
        ) : (
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={4} sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                </Grid>
                <Grid item xs={12} sm={8} md={8} component={Box}>
                    <Box className="App" sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}>
                        <Button variant="contained" color="primary" onClick={signInWithGoogle}>Sign in with Google</Button>
                    </Box>
                </Grid>
            </Grid>

        )
    );
}

export default App;
