import React, { useEffect } from 'react'; // Import useEffect
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom'; // Import useLocation
import { Box, Drawer, AppBar, CssBaseline, Toolbar, Typography, List, ListItem, ListItemButton, ListItemText, Avatar, Menu, MenuItem } from '@mui/material';
import Dashboard from './Dashboard';
import Instance from './Instance';
import Notebooks from './Notebooks';
import NotebookViewer from './NotebookViewer'; // Import the new component
import firebase from 'firebase/app';
import 'firebase/auth';

const drawerWidth = 240;

function Sidebar() {
    const [path, setPath] = React.useState(window.location.pathname);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const user = firebase.auth().currentUser;
    const location = useLocation(); // Access the current location object

    useEffect(() => {
        // This effect runs when the location changes
        setPath(location.pathname); // Update the path state with the new location
    }, [location]); // Dependency array includes location, so the effect runs on location change

    const formatPathToText = (path) => {
        const pathsToText = {
            '/': 'Ohalo Data - Dashboard', // Default to 'Ohalo Data - Dashboard' if path is '/'
            '/dashboard': 'Ohalo Data - Dashboard',
            '/instances': 'Ohalo Data - JupyterLab Instances',
            '/notebooks': 'Ohalo Data - Notebooks',
        };

        // Check if the path starts with '/notebooks/html/' and format accordingly
        if (path.startsWith('/notebooks/html/')) {
            const notebookPath = path.replace('/notebooks/html/', ''); // Remove the prefix
            return decodeURIComponent(notebookPath); // Decode and return the readable path
        }

        console.log(path);
        return pathsToText[path] || 'Unknown'; // Default to 'Unknown' if path is not found
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        firebase.auth().signOut();
    };

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#1976d2' }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        {formatPathToText(path)}
                    </Typography>
                </Toolbar>
                <Avatar
                    alt="User Avatar"
                    src={user ? user.photoURL : ""}
                    onClick={handleClick}
                    sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16 }}
                />
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'avatar-button',
                    }}
                >
                    <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                </Menu>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
                anchor="left"
            >
                <Toolbar /> {/* This ensures the content of the drawer starts below the AppBar */}
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {[
                            { text: 'Dashboard', path: '/dashboard' },
                            { text: 'Instances', path: '/instances' },
                            { text: 'Notebooks', path: '/notebooks' },
                        ].map(({ text, path }) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton component={Link} to={path}>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                <Toolbar /> {/* This empty Toolbar component is used to offset the content below the AppBar */}
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/instances" element={<Instance />} />
                    <Route path="/notebooks" element={<Notebooks />} />
                    <Route path="/notebooks/html/:path" element={<NotebookViewer />} />
                    {/* Redirect to /dashboard as a default route */}
                    <Route path="*" element={<Dashboard />} />
                </Routes>
            </Box>

        </Box>
    );
}

export default Sidebar;
