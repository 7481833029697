import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SvgIcon from '@mui/material/SvgIcon';
import firebase from 'firebase/app';
import 'firebase/auth';
import InstanceCard from './InstanceCard';


function Instance() {
    const user = firebase.auth().currentUser;
    const [instance, setInstance] = useState(null);
    const [instanceStatus, setInstanceStatus] = useState(null);
    const [instanceTypes, setInstanceTypes] = useState(null);
    const [startInstanceLoading, setStartInstanceLoading] = useState(false);
    const [instanceQueried, setInstanceQueried] = useState(false);

    const startInstance = () => {
        if (user) {
            setStartInstanceLoading(true);
            const abortController = new AbortController(); // Create an abort controller
            const signal = abortController.signal; // Get the signal

            user.getIdToken().then(function (idToken) {
                fetch(`${process.env.REACT_APP_API_URL}/api/instances/instance/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                })
                    .then(response => response.json())
                    .then(data => setInstance(data.instance));
            });
        }
        return false;
    }

    useEffect(() => {
        if (!user) {
            return; // Exit early if instance or user is undefined
        }

        user.getIdToken().then(function (idToken) {
            fetch(`${process.env.REACT_APP_API_URL}/api/instances/instance/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setInstance(data.instance);
                    setInstanceQueried(true);
                });
        });

    }, [user]); // Dependencies array

    useEffect(() => {
        if (user) {
            user.getIdToken().then(function (idToken) {
                fetch(`${process.env.REACT_APP_API_URL}/api/instances/instance-types`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                })
                    .then(response => response.json())
                    .then(data => setInstanceTypes(data.instance_types));
            });
        }
    }, [user]);


    // Render other content when instance is not null and instanceStatus is not 'PROVISIONING'
    return (
        <>
            <Typography
                component="h4"
                variant="h4"
                align="left"
                color="text.primary"
                gutterBottom
            >
                Instance Instructions
            </Typography>
            <Typography variant="p" align="left" color="text.secondary" paragraph>
                Instances are used to run JupyterLab, a web-based interactive development environment for Jupyter notebooks, code, and data. Click the button below to access your JupyterLab instance.
                These instances are launched on demand for each user, and will automatically sleep after 30 minutes of inactivity. You can also manually stop the instance by clicking the "Stop Instance" button.
            </Typography>
            {!startInstanceLoading && instanceQueried && !instance &&
                <Button variant="contained" color="primary"
                    onClick={startInstance}>
                    Start JupyterLab
                </Button>
            }
            {!startInstanceLoading && !instanceQueried && !instance &&
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress /><Typography>checking for existing instance</Typography>
                    </Box>
                </Box>
            }
            {(startInstanceLoading || instance) &&
                <InstanceCard
                    instance={instance}
                />
            }
        </>
    );
}

export default Instance;
