import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Card, CardContent, Typography, Grid, Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

function Notebooks() {
    const [notebooks, setNotebooks] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const user = firebase.auth().currentUser;

        if (user) {
            user.getIdToken(true).then(function(idToken) {
                fetch(`${process.env.REACT_APP_API_URL}/api/notebooks/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                })
                .then(response => response.json())
                .then(data => setNotebooks(data.notebooks));
            });
            setLoading(false);
        }
    }, []);

    return (
        <div>
            {notebooks ? 
            <Grid container spacing={3}>
                {notebooks.map((notebook, index) => (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <MuiLink component={RouterLink} to={`/notebooks/html/${encodeURIComponent(notebook.path)}`}>
                                        {notebook.filename}
                                    </MuiLink>
                                </Typography>
                                <Typography color="textSecondary">
                                    {notebook.message.eventTime}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {notebook.message.attributes.objectId}
                                </Typography>
                                {notebook.screenshot_url && 
                                    <img src={notebook.screenshot_url} alt="Notebook screenshot" />
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress/></div> }
        </div>
    );
}

export default Notebooks;