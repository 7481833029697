import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState } from 'react';


function InstanceCard({ instance }) {
    const user = firebase.auth().currentUser;

    useEffect(() => {
        if (!instance || !user) {
            return; // Exit early if instance or user is undefined
        }
        let intervalId = setInterval(() => {
            user.getIdToken().then(function (idToken) {
                fetch(`${process.env.REACT_APP_API_URL}/api/instances/instance/${instance.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        setInstanceStatus(data.status);
                        if (data.status === 'ACTIVE') {
                            clearInterval(intervalId); // Clear the interval once the instance is active
                        }
                    });
            });
        }, 3000); // Poll every 3 seconds

        // Cleanup function to clear the interval when the component unmounts or dependencies change
        return () => clearInterval(intervalId);
    }, [instance, user]); // Dependencies array

    useEffect(() => {
        if (!user) {
            return; // Exit early if instance or user is undefined
        }
        if(instance) {
            return; // Exit early if instance is defined
        }
        let intervalId = setInterval(() => {
            user.getIdToken().then(function (idToken) {
                fetch(`${process.env.REACT_APP_API_URL}/api/instances/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                })
                    .then(response => response.json())
                    .then(data => setInstanceTypes(data.instance));
            });
        }, 3000); // Poll every 3 seconds

        // Cleanup function to clear the interval when the component unmounts or dependencies change
        return () => clearInterval(intervalId);
    }, [user]);

    // Check if instance is null and return null or a loading indicator
    if (!instance) {
        return (
            <Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card elevation={3} sx={{ p: 2, borderRadius: 2, mb: 2 }}>
                        <CardHeader title="Starting Instance" />
                        <CardActions disableSpacing>
                            <Button variant="text" color="secondary">
                                EDIT
                            </Button>
                            <Button variant="text" color="primary" onClick={() => window.open(instance.proxy_uri, '_blank')}>
                                <CircularProgress />
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Card elevation={3} sx={{ p: 2, borderRadius: 2, mb: 2 }}>
                    <CardHeader title={instance.id} />
                    <CardContent>
                        <Typography variant="body1">{instance.machine_type}</Typography>
                        <Typography variant="body1">${parseFloat(instance.cost_per_hour).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / hour</Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button variant="text" color="secondary">
                            EDIT
                        </Button>
                        <Button variant="text" color="primary" onClick={() => window.open(instance.proxy_uri, '_blank')}>
                            LAUNCH JUPYTERLAB
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}

export default InstanceCard;
