import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { CircularProgress } from '@material-ui/core';

function NotebookViewer() {
    const { path } = useParams();
    const [notebookHtml, setNotebookHtml] = useState('');
    const user = firebase.auth().currentUser;
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchNotebookHtml = async () => {
            if (user) {
                user.getIdToken().then(function (idToken) {
                    fetch(`${process.env.REACT_APP_API_URL}/api/notebooks/${encodeURIComponent(path)}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${idToken}`
                        }
                    })
                        .then(response => response.text())
                        .then(data => setNotebookHtml(data));
                });
                setLoading(false);
            }
        };

        fetchNotebookHtml();
    }, [path, user]);

    return (
        loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress/></div> :
        <div dangerouslySetInnerHTML={{ __html: notebookHtml }} />
    );
}

export default NotebookViewer;