import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

function User() {
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        const user = firebase.auth().currentUser;

        if (user) {
            user.getIdToken(true).then(function(idToken) {
                fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                })
                .then(response => response.json())
                .then(data => setUserName(data.user.name));
            });
        }
    }, []);

    return (
        <div>
            {userName ? <h1>Hello, {userName}</h1> : <h1>Loading...</h1>}
        </div>
    );
}

export default User;